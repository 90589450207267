let Vue;

function formatComponentName(vm) {
  if (vm.$root === vm) {
    return 'root instance';
  }
  const name = vm._isVue ? vm.$options.name || vm.$options._componentTag : vm.name;
  return (
    (name ? `component <${name}>` : 'anonymous component') +
    (vm._isVue && vm.$options.__file ? ` at ${vm.$options.__file}` : '')
  );
}

function configError() {
  const _oldOnError = Vue.config.errorHandler;
  Vue.config.errorHandler = function VueErrorHandler(error, vm) {
    const extra = {
      componentName: formatComponentName(vm),
      propsData: vm.$options.propsData,
    };

    console.dir(extra);
    console.dir(error.toString());

    if (typeof _oldOnError === 'function') {
      _oldOnError.call(this, error, vm);
    }
  };
}

function install(Vue_) {
  if (Vue && Vue_ === Vue) {
    if (process.env.NODE_ENV !== 'production') {
      console.error('[vueerr] already installed. Vue.use(vueerr) should be called only once.');
    }
    return;
  }
  Vue = Vue_;
  configError();
}

export default { install };
