import { http } from 'onc';

export default {
  namespaced: true,
  state: {
    teams: [],
  },
  mutations: {
    SET_TEAMS(state, newValue) {
      state.teams = newValue;
    },
    ADD_TEAM(state, team) {
      state.teams.unshift(team);
    },
    DELETE_TEAM(state, id) {
      const index = state.teams.findIndex(({ _id }) => _id === id);
      if (index >= 0) {
        state.teams.splice(index, 1);
      }
    },
    UPDATE_TEAM(state, { id, name }) {
      const team = state.teams.find(({ _id }) => _id === id);
      if (team) {
        team.name = name;
      }
    },
  },
  getters: {
    currentTeams(state) {
      return state.teams.length ? state.teams : [];
    },
    getTeam: state => id => state.teams.find(({ _id }) => _id === id),
    teamName: state => id => {
      const team = state.teams.find(({ _id }) => _id === id);
      return team ? team.name : id;
    },
  },
  actions: {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({ state, dispatch }) {},

    async fetchTeams({ commit, state, rootState }, { refresh = false } = {}) {
      if (refresh || state.teams.length === 0) {
        const {
          ok,
          data: { teams, message },
        } = await http.fetchAndHandle('/api/admin/teams');
        if (ok && teams) {
          commit('SET_TEAMS', teams);
        } else if (message) {
          throw new Error(message);
        }
      }
      return state.teams;
    },

    async deleteTeam({ commit, state, rootState }, { id }) {
      const url = `/api/admin/team/${id}`;

      const {
        ok,
        data: { message },
      } = await http.fetchAndHandle(url, {
        method: 'delete',
        credentials: 'same-origin',
      });

      if (!ok) {
        throw new Error(message);
      } else {
        commit('DELETE_TEAM', id);
      }
    },

    async createTeam({ commit, state, rootState }, { name }) {
      const url = `/api/admin/team/create`;

      const {
        ok,
        data: { _id, modifiedDate, message },
      } = await http.fetchAndHandle(url, {
        method: 'post',
        body: JSON.stringify({ name }),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });

      if (!ok) {
        throw new Error(message);
      }
      commit('ADD_TEAM', { _id, name, modifiedDate });
    },
    async updateTeam({ commit, state, rootState }, { id, name }) {
      const {
        ok,
        data: { message },
      } = await http.fetchAndHandle(`/api/admin/team/${id}`, {
        method: 'put',
        body: JSON.stringify({ name }),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });

      if (!ok) {
        throw new Error(message);
      } else {
        commit('UPDATE_TEAM', { id, name });
      }
    },
  },
};

// ===
// Private helpers
// ===
