import Vue from 'vue';

export function vVisible(el, binding) {
  const value = binding.value;

  if (value) {
    el.style.visibility = 'visible';
  } else {
    el.style.visibility = 'hidden';
  }
}

const vVisiblePlugin = {
  install(vue, options) {
    Vue.directive('visible', vVisible);
  },
};

export default vVisiblePlugin;
