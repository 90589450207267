import store from '@state/store.js';
import loader from '../loader.js';

export default [
  {
    path: '/',
    name: 'home',
    component: () => loader.load('general/home.js'),
  },
  {
    path: '/login/reset-password',
    component: () => loader.load('login/reset.js'),
  },
  {
    path: '/login',
    component: () => loader.load('login/login.js'),
    name: 'login',
  },
  {
    path: '/login/sendreset',
    name: 'sendreset',
    component: () => loader.load('login/sendreset.js'),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('auth/logOut');
      const authRequiredOnPreviousRoute = routeFrom.matched.some(route => route.meta.authRequired || true);
      // Navigate back to previous page, or home as a fallback
      next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom });
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => loader.load('general/about.js'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => loader.load('general/privacy-policy.js'),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => loader.load('general/faq.js'),
  },
  {
    path: '/research',
    name: 'research',
    component: () => loader.load('general/research.js'),
  },
  {
    path: '/training',
    name: 'training',
    component: () => loader.load('general/training.js'),
  },
  {
    path: '/admin',
    component: () => loader.load('admin/admin-root.js'),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '',
        name: 'admin',
        component: () => loader.load('admin/admin.js'),
      },
      {
        path: 'learning',
        name: 'learning',
        component: () => loader.load('admin/learning.js'),
      },
      {
        path: 'sessions/upload',
        name: 'upload',
        component: () => loader.load('sessions/upload.js'),
      },
      {
        path: 'session/:id',
        name: 'session',
        component: () => loader.load('sessions/session.js'),
      },
      {
        path: 'sessions',
        name: 'sessions',
        component: () => loader.load('sessions/sessions.js'),
      },
      {
        path: 'data',
        name: 'data',
        component: () => loader.load('data/data.js'),
      },
      // global users
      {
        path: 'users/create',
        name: 'users-create',
        component: () => loader.load('global/create.js'),
      },
      {
        path: 'users/:id',
        name: 'user',
        component: () => loader.load('global/user.js'),
      },

      {
        path: 'users',
        name: 'users',
        component: () => loader.load('global/users.js'),
      },
      {
        path: 'teams/:id',
        name: 'team',
        component: () => loader.load('teams/team.js'),
      },
      {
        path: 'teams',
        name: 'teams',
        component: () => loader.load('teams/teams.js'),
      },
      {
        path: 'team/:id/users',
        name: 'team-usersadd',
        component: () => loader.load('teams/adduser.js'),
      },
      {
        path: 'team/users',
        name: 'team-users',
        component: () => loader.load('teams/users.js'),
      },
    ],
  },
  // { path: '/:remainder*', redirect: { name: 'home' } },
  { path: '*', redirect: { name: 'home' } },
];
