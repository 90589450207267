import router from '@router/index.js';
import store from '@state/store.js';
import format from 'date-fns/format';
import Vue from 'vue';
import VueMaterialAdapter from 'vue-material-adapter';
import RouterSync from 'vuex-router-sync';
import App from './app.js';
import vueerr from './components/error.js';
import vVisible from './components/v-visible.js';
import loader from './loader.js';
import roles from './roles.js';
import register from './register.js';

export default function main() {
  Vue.filter('isoDate', value => {
    return value ? format(value, 'YYYY-MM-DD HH:mm') : 'n/a';
  });

  Vue.use(vueerr);
  Vue.use(VueMaterialAdapter);
  Vue.component('oncSnackbar', () => loader.load('components/onc-snackbar.js'));
  Vue.component('navbar', () => loader.load('components/navbar.js'));
  Vue.use(vVisible);
  RouterSync.sync(store, router);

  store.dispatch('rbac/perms', { roles });

  Vue.component('ocs-footer', () => loader.load('components/ocs-footer.js'));
  Vue.component('admin-header', () => loader.load('components/admin-header.js'));

  // store.dispatch('rbac/perms', { roles });
  return new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
}

register(store);
main();
