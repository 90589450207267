/* eslint quote-props: 0 */
import jwtDecode from 'jwt-decode';
import { http } from 'onc';

const namespaced = true;

export default {
  namespaced,
  state: {
    currentUser: getSavedState('auth.currentUser'),
    currentRbac: null,
  },
  mutations: {
    SET_CURRENT_USER(state, newValue = null) {
      const { accessToken } = newValue || {};
      state.currentUser = newValue;
      saveState('auth.currentUser', newValue);
      setDefaultAuthHeaders(accessToken);
    },
    SET_CURRENT_RBAC(state, newValue) {
      state.currentRbac = newValue;
    },
  },
  getters: {
    // Whether the user is currently logged in.
    loggedIn(state) {
      return !!state.currentUser;
    },
    myRole(state) {
      return state.currentUser && state.currentUser.roles.role;
    },
    myTeams(state) {
      return state.currentUser && state.currentUser.roles.teams;
    },
    can: state => (operation, params) => {
      return (
        state.currentRbac.can &&
        state.currentUser &&
        state.currentRbac.canSync(state.currentUser.roles.role, operation, params)
      );
    },
  },
  actions: {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({ state, dispatch, getters }) {
      setDefaultBeforeFetch({ state, dispatch, getters });
      return dispatch('validate');
    },

    perms({ commit }, rbac) {
      commit('SET_CURRENT_RBAC', rbac);
    },
    // Logs in the current user.
    async logIn({ commit, dispatch, getters }, { username, password } = {}) {
      const response = await fetch('/token', {
        method: 'post',
        body: JSON.stringify({
          username,
          password,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const { user = null } = await response.json();
        if (user) {
          if (!user.name) {
            user.name = 'n/a';
          }
          const {
            payload: { sub, ...roles },
            ...info
          } = user;
          info.sub = sub;
          info.tokenData = getPayload(user.accessToken);
          info.roles = roles;
          commit('SET_CURRENT_USER', info);
          return info;
        }
      }
      throw new Error(response);
    },

    async loginWithCode({ commit, dispatch, getters }, { password, confirm, code } = {}) {
      const response = await fetch(`/api/reset/${code}`, {
        method: 'put',
        body: JSON.stringify({ password, confirm }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok && response.status !== 422) {
        throw Error(response.statusText);
      }

      const contentType = response.headers.get('content-type');
      const haveJson = contentType && contentType.indexOf('application/json') !== -1;

      if (haveJson) {
        return response
          .json()
          .then(({ message }) => {
            return message;
          })
          .catch(e => {
            console.dir(e);
            return 'unknown error';
          });
      }
    },

    async requestResetCode({ commit, dispatch, getters }, { subject } = {}) {
      const isClient = location.pathname.startsWith('/client');

      const response = await fetch(`${isClient ? '/client' : ''}/api/send-reset-email`, {
        method: 'post',
        body: JSON.stringify({ subject }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        return;
      }

      throw new Error(response);
    },

    // Logs out the current user.
    async logOut({ state, commit }) {
      const { accessToken } = state.currentUser;
      const response = await fetch(`/logout`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      });
      if (response.ok) {
        commit('SET_CURRENT_USER', null);
        return;
      }
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    validate({ commit, state }) {
      const user = state.currentUser;
      if (!user) {
        return Promise.resolve(null);
      }

      const { accessToken } = user;

      setDefaultAuthHeaders(accessToken);
      return Promise.resolve(state.currentUser);
    },
  },
};

// ===
// Private functions
// ===

function getPayload(token) {
  if (token) {
    return jwtDecode(token);
  }
}

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

function setDefaultAuthHeaders(accessToken) {
  http.defaults.headers.common.Authorization = accessToken ? `Bearer ${accessToken}` : '';
}

function setDefaultBeforeFetch({ state, dispatch, getters }) {
  http.defaults.beforeFetch = fetchOptions => {
    // If auth is required and the user is logged in...
    // validate (which will refresh the token as necessary)
    if (getters['loggedIn']) {
      return dispatch('validate');
    }

    return Promise.resolve();
  };
}
