export default {
  render(h) {
    return h('div', { attrs: { id: 'app' } }, [
      h('router-view', { attrs: { key: this.$route.fullPath } }, {}),
      h(
        'onc-snackbar',
        {
          ref: 'snackbar',
        },
        {},
      ),
    ]);
  },
  mounted() {
    this.$store.commit('setSnackbarHandler', this.onSnack);
  },
  methods: {
    onSnack(snack) {
      const snackbar = this.$refs.snackbar;
      if (snackbar) {
        snackbar.handleSnack(snack);
      }
    },
  },
};
